import { Component, OnInit, Input } from '@angular/core';
@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss']
})
export class OnboardingComponent {

  @Input('requiriments')
  requiriments: string[] = ['atual', 'carteira'];
  checked: boolean = false;
  checkeds: any = {};
  loading = true;

}
