import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { AuthFolderService } from 'src/app/callback-folder/auth-folder.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  url = 'https://reports.services.empreenderdinheiro.com.br';
  constructor(
    public httpCliente: HttpClient,
    public authFolderService: AuthFolderService,
    private oidcSecurityService: OidcSecurityService,
  ) { }
  get header() {
    let token = this.oidcSecurityService.getToken();
    if(this.authFolderService.authenticated()) {
      token = this.authFolderService.getAccess();
    }
    return {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + token,
      }),
    };
  }
  getFolderInfo() {
    return this.httpCliente.get([environment.url, 'users/member/folderInfo'].join(''), this.header);
  }
  downloadApoloReport(payload) {
    return this.httpCliente.post([this.url, 'report/apolo'].join('/'), payload, {
      headers: {
        "Content-Type": "text/plain",

      },
      responseType: 'text',
    });
  }
}
