import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { LOCALE_ID, NgModule, APP_INITIALIZER, } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { BrMaskDirective, BrMaskerModule } from 'br-mask';
import { HighchartsChartModule } from 'highcharts-angular';
import { ChartsModule } from 'ng2-charts';
import { NgxPaginationModule } from 'ngx-pagination';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CallbackComponent } from './callback/callback.component';
import { DefaultModule } from './layouts/default/default.module';
import { ModalStartComponent } from './modal-start/modal-start.component';
import { AportesComponent } from './modules/aportes/aportes.component';
import { CadastroComponent } from './modules/cadastro/cadastro.component';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { ProjectionChartComponent } from './modules/dashboard/projection-chart/projection-chart.component';
import { LoginComponent } from './modules/login/login.component';
import { InputNumberComponent } from './modules/painel-controle/input-number/input-number.component';
import { InputYearyOrMontlyComponent } from './modules/painel-controle/input-yeary-or-montly/input-yeary-or-montly.component';
import { PainelControleComponent } from './modules/painel-controle/painel-controle.component';
import { RecoveryPasswordComponent } from './modules/recovery-password/recovery-password.component';
import { AuthGuard } from './shared/auth-guard.service';
import { ItemActionComponent } from './shared/components/item-action/item-action.component';
import { NgxTabsModule } from './shared/components/tabs/ngx-tabs.module';
import { DividaPipe } from './shared/divida.pipe';
import { MilionMoneyPipe } from './shared/milion-money.pipe';
import { NormalMoneyPipe } from './shared/normal-money.pipe';
import { SharedModule } from './shared/shared.module';
import { configureAuth } from './auth.config';
import { AuthModule, OidcConfigService } from 'angular-auth-oidc-client';
import { CallbackFolderComponent } from './callback-folder/callback-folder.component';
import { NgxFaviconModule } from 'ngx-favicon';
import { customFavicons, AvailableCustomFavicons } from 'src/app/shared/components/header/favicon.config';
import { NgxPermissionsModule } from 'ngx-permissions';

registerLocaleData(localePt);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    CadastroComponent,
    PainelControleComponent,
    NormalMoneyPipe,
    MilionMoneyPipe,
    DividaPipe,
    ModalStartComponent,
    ItemActionComponent, RecoveryPasswordComponent,
    CallbackComponent,
    DashboardComponent,
    InputYearyOrMontlyComponent,
    InputNumberComponent,
    AportesComponent,
    ProjectionChartComponent,
    CallbackFolderComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    ChartsModule,
    BrMaskerModule,
    NgxTabsModule,
    MatSelectModule,
    MatTooltipModule,
    MatSlideToggleModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    DefaultModule,
    MatDialogModule,
    NgxChartsModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatButtonToggleModule,
    MatExpansionModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    HighchartsChartModule,
    NgxPaginationModule,
    MatSliderModule,
    MatStepperModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatChipsModule,
    MatTabsModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    AuthModule.forRoot(),
    NgxFaviconModule.forRoot<AvailableCustomFavicons>({
      faviconElementId: 'favicon',
      defaultUrl: 'favicon.ico',
      custom: customFavicons,
    }),
    NgxPermissionsModule.forRoot()
  ],
  providers: [AuthGuard,
    {
      provide: LOCALE_ID,
      useValue: "pt-BR"
    },
    BrMaskDirective,
    OidcConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: configureAuth,
      deps: [OidcConfigService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
