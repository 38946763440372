import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { BrMaskModel, BrMaskDirective } from 'br-mask';

@Component({
  selector: 'app-input-yeary-or-montly',
  templateUrl: './input-yeary-or-montly.component.html',
  styleUrls: ['./input-yeary-or-montly.component.scss']
})
export class InputYearyOrMontlyComponent {

  @Input()
  formGroup: FormGroup;

  inputValue = new FormControl();

  @Input()
  align: string = 'right';

  @Input()
  disableType: boolean = false;

  @Input()
  prefix: string;

  @Input()
  suffix: string;


  @Input()
  money: boolean = true;
  @Input()
  thousand: string = '.';
  @Input()
  decimalCaracter: string = ',';
}
