import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CallbackComponent } from './callback/callback.component';
import { DefaultComponent } from './layouts/default/default.component';
import { CadastroComponent } from './modules/cadastro/cadastro.component';
import { PainelControleComponent } from './modules/painel-controle/painel-controle.component';
import { LoginComponent } from './modules/login/login.component';
import { RecoveryPasswordComponent } from './modules/recovery-password/recovery-password.component';
import { AuthGuard } from './shared/auth-guard.service';
import { AportesComponent } from './modules/aportes/aportes.component';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { CallbackFolderComponent } from './callback-folder/callback-folder.component';

const routes: Routes = [
  {
    path: '',
    component: DefaultComponent,
    canActivate: [AuthGuard],
    children: [
      {
        redirectTo: '/painel-controle',
        path: '',
        pathMatch: 'full',
      },
      {
        path: 'login',
        component: DefaultComponent
      },
      {
        path: 'painel-controle',
        component: PainelControleComponent,
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
      },
      {
        path: 'aportes',
        component: AportesComponent,
      },

    ],
  },
  {
    path: 'callback',
    component: CallbackComponent,
  },
  {
    path: 'folder-token/callback/:token',
    component: CallbackFolderComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
