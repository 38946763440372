<div *ngIf="formGroup" class="row">
    <div class="col-7">

        <app-input-number [align]="align" [prefix]="prefix" [suffix]="suffix" [control]="formGroup.get('value')">
        </app-input-number>

    </div>
    <div class="col-5" *ngIf='!disableType'>
        <mat-form-field name="type" style="width: 100%;">
            <mat-select matInput name="type" [formControl]="formGroup.get('type')">
                <mat-option value="montly">Mensal</mat-option>
                <mat-option value="yearly">Anual</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>