import { Component, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { ApoloService } from '../../shared/apolo.service';

@Component({
  selector: 'app-aportes',
  templateUrl: './aportes.component.html',
  styleUrls: ['./aportes.component.scss'],
})
export class AportesComponent implements OnInit {
  numberOfInputs: number = 0;
  formGroup: FormGroup;
  formYear: FormArray;
  constructor(public apolo: ApoloService) {
    this.formGroup = apolo.formGroup;
    this.formYear = apolo.formYear;
  }
  ngOnInit() {
    const initialInputValue = this.formGroup.get('periodoDeAplicacao').value;
    const inputNumber = parseInt(initialInputValue, 10);

    if (!isNaN(inputNumber)) {
      this.updateVisibleInputs(initialInputValue);
    } else {
      this.updateVisibleInputs(0);
    }
  }

  applyToAll(item) {
    for (let i = 0; i < this.numberOfInputs; i++) {
      const control = this.formYear.controls[i];

      if (control) {
        control.get('dynamic').setValue(item.get('dynamic').value);
      }
      for (
        let i = this.formYear.controls.length - 1;
        i >= this.numberOfInputs;
        i--
      ) {
        const control = this.formYear.controls[i];

        if (control) {
          control.get('dynamic').setValue(0);
        }
      }
    }
    console.log(this.formYear.value);
    this.apolo.reloadInfo$.next(true);
  }
  updateVisibleInputs(inputCount: number) {
    this.numberOfInputs = Math.max(
      0,
      Math.min(inputCount, this.formYear.controls.length)
    );
  }
  onInputChange(event: any) {
    const inputNumber = parseInt(event.target.value, 10);
    if (!isNaN(inputNumber) && inputNumber <= this.formYear.controls.length) {
      this.updateVisibleInputs(inputNumber);
    }
  }
}
