import { Component, OnInit } from '@angular/core';
import { ApoloService } from './../../shared/apolo.service';
import { ReportsService } from './reports.service';
import { map } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  projectionFormation: any;
  lastItemValue: any;
  downloadObject: any;
  lastItemAcumulado: any;
  jurosEfetivos: any;
  jurosEfetivosPercentage: any;
  rpm: any;
  print = false;
  loadingDownload = false;


  firstMillion: any;
  percentJuros: any;

  constructor(
    public apolo: ApoloService,
    public reportsService: ReportsService,
    private oidcSecurityService: OidcSecurityService,
  ) {
    this.projectionFormation = this.apolo.projectionFormation();
    const lastItem = this.apolo.lastItem();
    this.lastItemValue = lastItem
      .pipe(
        map((item) => this.apolo.patrimonioWithFixInflation(item))
      );

    this.lastItemAcumulado = lastItem
      .pipe(
        map((item) => item.acumulado)
      );
    this.jurosEfetivos = this.apolo.lastItemJurosEfetivos();
    this.jurosEfetivosPercentage = this.apolo.lastItemJurosEfetivosPercentage();
    this.rpm = this.apolo.rpmLastItem();

    combineLatest(
      this.lastItemValue,
      this.lastItemAcumulado,
      this.jurosEfetivos,
      this.jurosEfetivosPercentage,
      this.rpm,
    )
      .subscribe((
        result,
      ) => this.downloadObject = {
        lastItemValue: result[0],
        lastItemAcumulado: result[1],
        jurosEfetivos: result[2],
        jurosEfetivosPercentage: result[3],
        capitalInicialPercentage: (1 - (result[3] as number)),
        rpm: result[4],
      });

    this.apolo.firstMillion()
      .subscribe((item) => {
        this.firstMillion = item;
      });
    this.apolo.database().subscribe((yearList) => {
    });
  }
  async downloadData() {
    this.loadingDownload = true;
    const projectFormation = await this.getValue(this.projectionFormation);
    const projectionChart = await this.getValue(this.apolo.projectionChart());
    const userData = await this.getValue(this.oidcSecurityService.userData$);

    
    this.reportsService.getFolderInfo()
    .subscribe((users: any) => {
      const downloadData = {
        name: users.mainUser.firstName + ' ' + users.mainUser.lastName,
        consultant: users.folderUser.firstName + ' ' + users.folderUser.lastName,
        date: new Date(),
        config: {
          ...this.apolo.formGroup.value,
          dynamicAports: this.apolo.formYear.value.filter((_, i) => {
            console.log(i, this.apolo.formGroup.get('periodoDeAplicacao').value);
            return i < this.apolo.formGroup.get('periodoDeAplicacao').value;
          }),
        },
        projectFormation,
        projectionChart,
        periodoDeAplicacao: this.apolo.formGroup.get('periodoDeAplicacao').value,
        ...this.downloadObject,
        firstMillionTextDescription: this.firstMillionTextDescription,
        firstMillionText: this.firstMillionText,
      };
      this.reportsService.downloadApoloReport(downloadData)
      .subscribe((data) => {
        this.loadingDownload = false;
        var a = document.createElement("a"); //Create <a>
        a.href = "data:application/pdf;base64," + data; //Image Base64 Goes here
        a.download = "apolo.pdf"; //File name Here
        a.click(); //Downloaded file
      });
    });
    
  }
  getValue<T>(observable: Observable<T>): Promise<T> {
    return new Promise((done) => {
      observable.subscribe((data) => done(data))
    });
  }

  get voceTera() {
    return Number(Number(this.apolo.formGroup.get('capitalInicialYears').value) + Number(this.apolo.formGroup.get('periodoDeAplicacao').value));
  }
  get firstMillionTextDescription() {
    if (this.firstMillion == undefined) return '-';
    if (this.firstMillion.keyYear == 0) {
      return '-';
    }
    return (this.firstMillion.keyYear + 1) + ' anos ou ' + this.firstMillion.keyIndex + '  meses';
  }
  get firstMillionText() {
    if (this.firstMillion == undefined) return 'Sua projeção não chega a milhão.';
    const monthNames = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
      "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
    ];
    return monthNames[this.firstMillion?.date.getMonth()] + ', ' + this.firstMillion?.date.getFullYear();
  }
  makePrint() {
    this.print = true;
    document.getElementsByClassName('mat-drawer-content')[0].scrollTo({ top: 0 });
    setTimeout(() => {
      window.print();
      this.print = false;
    }, 3000);
  }
  ngOnInit(): void {
  }

}
