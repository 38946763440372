<div class="container-full" *ngIf="formGroup">
    <div _ngcontent-aoc-c205="" class="title">
        <h2 _ngcontent-aoc-c205="">Seja bem-vindo ao Sistema Apolo!</h2>
        <p _ngcontent-aoc-c205="">Você está vendo seu painel de controle</p>
    </div>
    <div style="margin-bottom: 12px;" class="card-painel">
        <div class="heading-internal" style="margin-bottom: 0px;">
            <div class="col-12 col-md-8">
                <div class="heading-left"
                    style="width: 5px;height: 32px;background-color: #00acc1;float: left;position: absolute;margin-left: -15px;">
                </div>
                <h2 class="heading-content" style="flex: 1;">
                    Painel de Controle
                    <span
                        matTooltip="No painel de controle você selecionará as premissas necessárias para traçar um planejamento patrimonial de longo prazo.">
                        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M7.26372 5.64398L8.86369 5.65378L8.87349 4.05381L7.27352 4.04401L7.26372 5.64398ZM8.00982 14.4487C4.48189 14.4271 1.62753 11.5376 1.64913 8.00965C1.67073 4.48171 4.56026 1.62735 8.0882 1.64896C11.6161 1.67056 14.4705 4.56009 14.4489 8.08802C14.4273 11.616 11.5378 14.4703 8.00982 14.4487ZM8.09799 0.0489861C7.04744 0.0425531 6.0059 0.243105 5.03286 0.639192C4.05981 1.03528 3.1743 1.61914 2.4269 2.35745C0.917449 3.84853 0.0621504 5.87816 0.0491585 7.99985C0.0361666 10.1215 0.866546 12.1615 2.35762 13.6709C3.09593 14.4183 3.97422 15.013 4.94234 15.421C5.91047 15.829 6.94947 16.0423 8.00002 16.0487C10.1217 16.0617 12.1617 15.2313 13.6711 13.7402C15.1806 12.2491 16.0359 10.2195 16.0489 8.09782C16.0553 7.04727 15.8547 6.00573 15.4587 5.03268C15.0626 4.05963 14.4787 3.17413 13.7404 2.42673C13.0021 1.67932 12.1238 1.08466 11.1557 0.676685C10.1876 0.268711 9.14855 0.055419 8.09799 0.0489861ZM7.22453 12.0439L8.8245 12.0537L8.85389 7.25375L7.25392 7.24395L7.22453 12.0439Z"
                                fill="black"></path>
                        </svg>
                    </span>
                    <!--container-->
                </h2>
            </div>
            <div class="col-12 col-md-3">
                <button (click)="redefinir()" mat-stroked-button class="d-none d-sm-block" style="margin-right: 24px;">
                    Redefinir Configurações </button>
            </div>
            <div class="col-md-1"></div>
        </div>
        <button mat-stroked-button class="d-block d-sm-none" style="margin-left: 32px;"> Redefinir Configurações
        </button>

        <div class="card-content">
            <form>
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label">
                        <div class="title-label">1.&nbsp; Idade atual:</div>
                    </label>
                    <div class="col-sm-5 col-md-5 col-xl-3">
                        <mat-form-field>
                            <span matSuffix><b>&nbsp;&nbsp; Anos</b></span>
                            <input matInput class="right" type="number" max="99" autofocus
                                [formControl]="formGroup.get('capitalInicialYears')">
                        </mat-form-field>

                    </div>
                    <div class="col-md-3 col-xl-6">
                        <div class="description">
                            <ul>
                                <li><span>Insira a idade atual</span></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-3 col-form-label">
                        <div class="title-label">2.&nbsp; Período de Aplicação:</div>
                    </label>
                    <div class="col-sm-5 col-md-5 col-xl-3">
                        <mat-form-field>
                            <span matSuffix><b>&nbsp;&nbsp; Anos</b></span>
                            <input matInput class="right" type="number" max="50" maxlength="2" autofocus
                                [formControl]="formGroup.get('periodoDeAplicacao')">
                        </mat-form-field>
                    </div>
                    <div class="col-md-3 col-xl-6">
                        <div class="description">
                            <ul>
                                <li><span>Atenção: Prazo máximo, 50 anos.</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label">
                        <div class="title-label">3.&nbsp; Capital Inicial:</div>
                    </label>
                    <div class="col-sm-5 col-md-5 col-xl-3">
                        <app-input-number align='left' [prefix]="'R$'" [control]="formGroup.get('capitalInicial')">
                        </app-input-number>

                    </div>
                    <div class="col-md-3 col-xl-6">
                        <div class="description">
                            <ul>
                                <li><span>Não considerar bens imóveis e automóveis, regra geral.</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label">
                        <div class="title-label">4.&nbsp; Expectativa de aporte:</div>
                    </label>
                    <div class="col-sm-6 col-md-6 col-xl-3">
                        <app-input-yeary-or-montly align='left' prefix="R$" [formGroup]="formGroup.get('aporte')">
                        </app-input-yeary-or-montly>
                        <div class="divisor"> <span>OU UTILIZE:</span></div>
                    </div>
                    <div class="col-md-3 col-xl-6">
                        <div class="description">
                            <ul>
                                <li><span>Selecione o seu modelo de aporte. Se possível, utilize aportes mensais.</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label">
                        <div style="padding: 0px!important;"> </div>
                    </label>
                    <div class="col-sm-5 col-md-5 col-xl-3" style="padding-top: 6px;">

                        <mat-slide-toggle color="accent" style="margin-bottom: 22px;"
                            [checked]="formGroup.get('dynamic').value" [formControl]="formGroup.get('dynamic')">
                            <span style="color: #666;">
                                Aportes Dinâmicos:
                            </span>
                        </mat-slide-toggle>
                        <label *ngIf="formGroup.get('dynamic').value==true"
                            style="text-align: center; color: #666; font-size: 8px;">
                            Clique para expandir
                            <button style="width: 100%; margin-bottom: 12px; color: #fff; background-color: #00acc1;"
                                mat-stroked-button color="secondary" [routerLink]="['/aportes']">
                                <img src="assets/images/aportes-dinamicos.svg"
                                    style="height: 16px; padding-right: 5px;" />
                                <strong>Aportes Dinâmicos</strong>
                            </button>
                        </label>
                    </div>
                    <div class="col-md-3 col-xl-6">
                        <div class="description">
                            <ul>
                                <li><span>Ative caso deseje alterar manualmente os aportes anuais</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="blue-content">
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">
                            <div class="title-label">5.&nbsp; Taxa de Retorno:</div>
                        </label>
                        <div class="col-sm-5 col-md-5 col-xl-3">
                            <app-input-yeary-or-montly money="false" [suffix]="'%'"
                                [formGroup]="formGroup.get('taxaDaAplicacao')">
                            </app-input-yeary-or-montly>
                        </div>
                        <div class="col-md-3 col-xl-6">
                            <div class="description">
                                <ul>
                                    <li><span>Evite considerar expectativa de rentabilidade apenas de Investimentos em
                                            Renda
                                            Variável.</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="green-content">
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">
                            <div class="title-label">6.&nbsp; Inflação:</div>
                        </label>
                        <div class="col-sm-5 col-md-5 col-xl-3">
                            <app-input-yeary-or-montly [disableType]="true" [formGroup]="formGroup.get('inflacao')"
                                [suffix]="'%'">
                            </app-input-yeary-or-montly>
                        </div>
                        <div class="col-md-3 col-xl-6">
                            <div class="description">
                                <ul>
                                    <li><span>Sugerimos como referência a meta de inflação de longo prazo do Banco
                                            Central do Brasil. (bcb.gov.br) </span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <mat-accordion style="margin-top: 24px!important;">
        <mat-expansion-panel hideToggle>
            <mat-expansion-panel-header class="panel-header"
                style="background: linear-gradient(270deg, rgba(0, 0, 0, 0.79) 100%, #000000 0%);">
                <mat-panel-title>
                    <div class="heading-left"
                        style="width: 5px;height: 32px;background-color: #00acc1;float: left;position: absolute;margin-left: -24px;">
                    </div>
                    <div style="font-size: 18px; color: white;">
                        <b>
                            Configurações Avançadas
                        </b> (Variáveis da Projeção)
                    </div>
                </mat-panel-title>
                <mat-flat-button class="button-header">
                    <p class="b">
                        <b>Clique para expandir</b>
                    </p>
                </mat-flat-button>
            </mat-expansion-panel-header>
            <div>
                <form>

                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">
                            <div class="title-label">1.&nbsp; Corrigir patrimônio pela inflação:</div>
                        </label>
                        <div class="col-sm-5 col-md-5 col-xl-3">
                            <mat-slide-toggle style="padding-top: 20px;"
                                [formControl]="formGroup.get('advancedConfiguration').get('fixInflation')"
                                [checked]="formGroup.get('advancedConfiguration').get('fixInflation').value">
                            </mat-slide-toggle>
                        </div>
                        <div class="col-md-3 col-xl-6">
                            <div class="description">
                                <ul>
                                    <li><span>Selecione essa opção para considerar o efeito da inflação no planejamento patrimonial.</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>


                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">
                            <div class="title-label">2.&nbsp; Corrigir aportes pela inflação:</div>
                        </label>
                        <div class="col-sm-5 col-md-5 col-xl-3">
                            <mat-slide-toggle style="padding-top: 20px;"
                                [checked]="formGroup.get('advancedConfiguration').get('fixInflationOnAporte').value"
                                [formControl]="formGroup.get('advancedConfiguration').get('fixInflationOnAporte')">
                            </mat-slide-toggle>
                        </div>
                        <div class="col-md-3 col-xl-6">
                            <div class="description">
                                <ul>
                                    <li><span>Faça com que os aportes cresçam na mesma medida da inflação
                                            desejada.</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">
                            <div class="title-label">3.&nbsp; Utilizar crescimento real dos aportes:</div>
                        </label>
                        <div class="col-sm-5 col-md-5 col-xl-3 ">
                            <div class="row">
                                <div class="col-3">
                                    <mat-slide-toggle style="padding-top: 20px;"
                                        [checked]="formGroup.get('advancedConfiguration').get('aporteCrescimento').get('value').value"
                                        [formControl]="formGroup.get('advancedConfiguration').get('aporteCrescimento').get('value')">
                                    </mat-slide-toggle>
                                </div>
                                <div class="col-9">
                                    <app-input-number [suffix]="'%'"
                                        [control]="formGroup.get('advancedConfiguration').get('aporteCrescimento').get('percentage')">
                                    </app-input-number>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-5">
                            <div class="description">
                                <ul>
                                    <li><span>Utilize essa taxa para projetar o crescimento dos aportes acima da
                                            correção pela inflação. Referência de 1% a 3%.</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">
                            <div class="title-label">4.&nbsp; Incluir imposto de renda:</div>
                        </label>
                        <div class="col-sm-5 col-md-5 col-xl-3">
                            <div class="row">
                                <div class="col-3">
                                    <mat-slide-toggle style="padding-top: 20px;"
                                        [checked]="formGroup.get('advancedConfiguration').get('impostoDeRenda').get('value').value"
                                        [formControl]="formGroup.get('advancedConfiguration').get('impostoDeRenda').get('value')">
                                    </mat-slide-toggle>
                                </div>
                                <div class="col-9">
                                    <app-input-number [suffix]="'%'"
                                        [control]="formGroup.get('advancedConfiguration').get('impostoDeRenda').get('percentage')">
                                    </app-input-number>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 col-xl-6">
                            <div class="description">
                                <ul>
                                    <li><span>Considere neste campo a alíquota média do imposto de renda sobre os seus
                                            resgates. Recomendável alíquota de 15%.</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </form>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
    <div class="height"></div>
    <div class="bottom-bar">
        <button [routerLink]="['/dashboard']" mat-flat-button color="primary" style="
    margin-left: 12px;
    margin-top: -3px;
">
            VER EVOLUÇÃO DOS APORTES
            <mat-icon>east</mat-icon>
        </button>
    </div>
</div>