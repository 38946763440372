import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AuthService } from '../../auth.service';
import { Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { CustomFavicon } from './favicon.config';
import { NgxFaviconService } from 'ngx-favicon';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Output() toggleSideBarForMe: EventEmitter<any> = new EventEmitter();

  public CustomFavicon: typeof CustomFavicon = CustomFavicon;

  origin: string = 'empreenderdinheiro';

  urls = {
    empreenderdinheiro: environment.url.replace('qa.api.', 'membro.qa.').replace('api.', 'membro.'),
    techfinance: environment.url.replace('qa.api.', 'techfinance.qa.').replace('api.', 'techfinance.'),
  };

  constructor(private ngxFaviconService: NgxFaviconService<CustomFavicon>, public authService: OidcSecurityService, public router: Router) {
    this.origin = this.getOrigin();
  }

  getOrigin(): string {
    if ((location as any).href.toString().split('tf').length > 1) {
      this.setCustomFavicon(CustomFavicon.FAVICON_TF);
      return 'techfinance';
    }
    if ((location as any).href.toString().split('techfinance').length > 1) {
      this.setCustomFavicon(CustomFavicon.FAVICON_TF);
      return 'techfinance';
    }
    this.setCustomFavicon(CustomFavicon.FAVICON_I50);
    return 'empreenderdinheiro';
  }

  public setCustomFavicon(faviconName: CustomFavicon): void {
    this.ngxFaviconService.setCustomFavicon(faviconName);
  }

  ngOnInit() { }

  toggleSideBar() {
    this.toggleSideBarForMe.emit();
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 300);
  }
  logout() {
    this.authService.logoff();
  }
}
