<div class="container-full" [ngClass]="{printContainer: print==true}">
    <div class="row">
        <div class="col-md-8">
            <div class="row heading-dashboad">
                <div class="col-12 col-md-7">
                    <div _ngcontent-aoc-c205="" class="title">
                        <h2 _ngcontent-aoc-c205="">Painel de Evolução Patrimonial</h2>
                        <p _ngcontent-aoc-c205="">Planejamento financeiro de longo prazo
                        </p>

                    </div>

                </div>
                <div class="col-12 col-md-5">
                    <div *ngIf="loadingDownload == true" style="padding: 8px;">
                        <mat-spinner style="float: right;" diameter="30" color="#ccc"></mat-spinner>
                    </div>
                    <div *ngIf="loadingDownload == false">

                        
                        <button *ngxPermissionsOnly="['techfinance::access']" class="hide-print" mat-flat-button color="primary" (click)="downloadData()" style="

                                                        margin-top: 8px;
                    background: #00acc1;
                    color: #fff; float: right;
                                                    ">
                            Gerar relatório
                            <span class="badge bg-secondary" style="background: #000; color: #fff;">BETA</span>

                            <mat-icon>print</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
            <div class="card-chart">
                <div class="card-chart-icon">
                    <mat-icon>
                        money
                    </mat-icon>
                </div>
                <div class="card-header-content">
                    <h3>
                        Seu patrimônio em {{apolo.formGroup.get('periodoDeAplicacao').value}} anos será:
                    </h3>
                    <p>Nesta data você terá
                        {{voceTera}}
                        anos</p>

                </div>
                <div class="card-value-content">
                    <div class="value">
                        {{
                        lastItemValue | async | currency:'BRL'
                        }}
                    </div>
                </div>
            </div>
            <div *ngIf="firstMillionTextDescription != '-'" class="card-chart card-chart-gold">
                <div class="card-chart-icon">
                    <mat-icon>
                        paid
                    </mat-icon>
                </div>
                <div class="card-header-content">
                    <h3>
                        Alcançará R$ 1 Milhão em:
                    </h3>
                    <p>{{firstMillionTextDescription}}</p>

                </div>
                <div class="card-value-content">
                    <div class="value">
                        {{firstMillionText}}
                    </div>
                </div>
            </div>

            <div class="card-chart card-chart-red">
                <div class="card-chart-icon">
                    <mat-icon>
                        savings_outlined
                    </mat-icon>
                </div>
                <div class="card-header-content">
                    <h3>
                        Capital Inicial + Aportes:
                    </h3>
                    <p>{{ 1 -(jurosEfetivosPercentage | async) | percent :'1.2-2'}} de capital próprio sobre o total</p>

                </div>
                <div class="card-value-content">
                    <div class="value">
                        {{
                        lastItemAcumulado | async | currency:'BRL'
                        }}


                    </div>
                </div>
            </div>
            <div class="card-chart card-chart-blue">
                <div class="card-chart-icon">
                    <mat-icon>
                        insights
                    </mat-icon>
                </div>
                <div class="card-header-content">
                    <h3>
                        Valor ganho em Juros:
                    </h3>
                    <p>{{jurosEfetivosPercentage | async | percent :'1.2-2'}} de juros sobre o total</p>

                </div>
                <div class="card-value-content">
                    <div class="value">
                        {{
                        jurosEfetivos | async | currency:'BRL'
                        }}


                    </div>
                </div>
            </div>
            <div class="card-chart card-chart-green"
                style="background: linear-gradient(270deg, rgba(0, 0, 0, 0.79) 100%, #000000 0%);">
                <div class="card-chart-icon">
                    <mat-icon>
                        signal_cellular_alt
                    </mat-icon>
                </div>
                <div class="card-header-content">
                    <h3>
                        Renda Passiva Mensal:
                    </h3>
                    <p>Aproximada</p>

                </div>
                <div class="card-value-content">
                    <div class="value" style="color: white;">
                        {{
                        rpm | async | currency:'BRL'
                        }}


                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div style="margin-bottom: 12px;" class="card-painel">
                <div class="heading-internal" style="margin-bottom: 0px;padding-right: 24px;">
                    <div class="heading-surface"></div>
                    <div style="flex: 1;">
                        <h2 class="heading-content" style="flex: 1;">
                            Processo de Formação do Patrimônio
                            <span
                                matTooltip="Confira a participação dos aportes e o efeito dos juros compostos no processo de formação patrimonial.">
                                <svg width="17" height="17" viewBox="0 0 17 17" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M7.26372 5.64398L8.86369 5.65378L8.87349 4.05381L7.27352 4.04401L7.26372 5.64398ZM8.00982 14.4487C4.48189 14.4271 1.62753 11.5376 1.64913 8.00965C1.67073 4.48171 4.56026 1.62735 8.0882 1.64896C11.6161 1.67056 14.4705 4.56009 14.4489 8.08802C14.4273 11.616 11.5378 14.4703 8.00982 14.4487ZM8.09799 0.0489861C7.04744 0.0425531 6.0059 0.243105 5.03286 0.639192C4.05981 1.03528 3.1743 1.61914 2.4269 2.35745C0.917449 3.84853 0.0621504 5.87816 0.0491585 7.99985C0.0361666 10.1215 0.866546 12.1615 2.35762 13.6709C3.09593 14.4183 3.97422 15.013 4.94234 15.421C5.91047 15.829 6.94947 16.0423 8.00002 16.0487C10.1217 16.0617 12.1617 15.2313 13.6711 13.7402C15.1806 12.2491 16.0359 10.2195 16.0489 8.09782C16.0553 7.04727 15.8547 6.00573 15.4587 5.03268C15.0626 4.05963 14.4787 3.17413 13.7404 2.42673C13.0021 1.67932 12.1238 1.08466 11.1557 0.676685C10.1876 0.268711 9.14855 0.055419 8.09799 0.0489861ZM7.22453 12.0439L8.8245 12.0537L8.85389 7.25375L7.25392 7.24395L7.22453 12.0439Z"
                                        fill="black"></path>
                                </svg>
                            </span>
                            <!--container-->
                        </h2>
                        <p style="font-size: 12px; font-weight: normal; color: #666;">Representatividade Aportes vs
                            Juros
                        </p>
                    </div>
                </div>

                <div *ngIf="projectionFormation" class="card-content">
                    <div class="table-responsive">
                        <table style="border: 1px solid #efefef;" class="table table-striped table-hover table-sm">
                            <thead>
                                <tr>
                                    <th>ANO</th>

                                    <th class="color-aporte">APORTE</th>
                                    <th colspan="3">COMPOSIÇÃO</th>
                                    <th class="color-juros">JUROS</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let year of projectionFormation | async">
                                    <td>
                                        <b>{{year.year}}º</b>
                                    </td>
                                    <td class="color-aporte">{{year.aportePercent | percent}}</td>
                                    <td colspan="3">
                                        <div
                                            style="width: 100%; background-color: #12da21; height: 12px; overflow: hidden; border-radius: 30px; margin-top: 6px;">
                                            <div class="percentage-blue"
                                                style="background-color: #109cf1; height: 12px;"
                                                [style.width]="year.aportePercent * 100 + '%'">
                                            </div>
                                        </div>
                                    </td>
                                    <td class="color-juros">{{year.jurosPercent | percent}}</td>
                                </tr>


                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div style="margin-top: 24px;" class="card-painel">
                <div class="heading-internal" style="margin-bottom: 0px;padding-right: 24px;">
                    <div class="heading-surface"></div>
                    <div style="flex: 1;">
                        <h2 class="heading-content" style="flex: 1;">
                            Evolução Patrimonial (R$ Mil)

                            <span
                                matTooltip="Confira a evolução do patrimônio considerando as premissas selecionadas no painel de controle.">
                                <svg width="17" height="17" viewBox="0 0 17 17" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M7.26372 5.64398L8.86369 5.65378L8.87349 4.05381L7.27352 4.04401L7.26372 5.64398ZM8.00982 14.4487C4.48189 14.4271 1.62753 11.5376 1.64913 8.00965C1.67073 4.48171 4.56026 1.62735 8.0882 1.64896C11.6161 1.67056 14.4705 4.56009 14.4489 8.08802C14.4273 11.616 11.5378 14.4703 8.00982 14.4487ZM8.09799 0.0489861C7.04744 0.0425531 6.0059 0.243105 5.03286 0.639192C4.05981 1.03528 3.1743 1.61914 2.4269 2.35745C0.917449 3.84853 0.0621504 5.87816 0.0491585 7.99985C0.0361666 10.1215 0.866546 12.1615 2.35762 13.6709C3.09593 14.4183 3.97422 15.013 4.94234 15.421C5.91047 15.829 6.94947 16.0423 8.00002 16.0487C10.1217 16.0617 12.1617 15.2313 13.6711 13.7402C15.1806 12.2491 16.0359 10.2195 16.0489 8.09782C16.0553 7.04727 15.8547 6.00573 15.4587 5.03268C15.0626 4.05963 14.4787 3.17413 13.7404 2.42673C13.0021 1.67932 12.1238 1.08466 11.1557 0.676685C10.1876 0.268711 9.14855 0.055419 8.09799 0.0489861ZM7.22453 12.0439L8.8245 12.0537L8.85389 7.25375L7.25392 7.24395L7.22453 12.0439Z"
                                        fill="black"></path>
                                </svg>
                            </span>
                            <!--container-->
                        </h2>
                        <p style="font-size: 12px; font-weight: normal; color: #666;">

                            {{apolo.formGroup.get('advancedConfiguration').get('fixInflation').value ? 'Patrimônio Real'
                            : 'Patrimônio Nominal'}}
                            |
                            {{apolo.formGroup.get('advancedConfiguration').get('aporteCrescimento').get('value').value ?
                            'Com Crescimento Salarial' : 'Sem Crescimento Salarial'}}
                            |
                            {{apolo.formGroup.get('advancedConfiguration').get('impostoDeRenda').get('value').value ?
                            'Com IR' : 'Sem IR'}}
                        </p>
                    </div>
                </div>

                <div class="card-content">
                    <div style="height: 80px; display: none;">
                        <div class="float-r" style="float: right;"
                            style="float: right;background: #00acc1;padding: 12px;border-radius: 10px;color: #fff;">
                            <div style="font-size: 12px;">Em {{ firstMillionText }} você terá:</div><b>{{
                                lastItemValue | async | currency:'BRL'
                                }}</b>
                        </div>
                    </div>
                    <app-projection-chart *ngIf="print==false"></app-projection-chart>
                    <app-projection-chart id="outra" *ngIf="print==true"></app-projection-chart>

                </div>
            </div>
        </div>
    </div>
    <div class="height"></div>
</div>