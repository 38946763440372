import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserService } from '../../shared/user.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-cadastro',
  templateUrl: './cadastro.component.html',
  styleUrls: ['./cadastro.component.scss'],
})
export class CadastroComponent implements OnInit {
  loading = false;
  submitted = false;
  error: string;

  loginForm = new FormGroup({
    fullName: new FormControl('', [
      Validators.required,
      Validators.pattern(
        /^(([A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]+[\-\']?)*([A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]+)?\s)+([A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]+[\-\']?)*([A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]+)?$/
      ),
    ]),
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(6),
    ]),
  });

  constructor(public userService: UserService, public router: Router, public activateRoute: ActivatedRoute, ) {
    this.activateRoute.params.subscribe((data) => {
      if (data.id == 'alunoempreenderdinheiro') localStorage.activedaccount = true;
    })
  }

  ngOnInit() {
    
  }

  getOrigin(): String {
    if ((location as any).href.toString().split('tf').length > 1) {
      return 'techfinance';
    }
    if ((location as any).href.toString().split('techfinance').length > 1) {
      return 'techfinance';
    }
    return 'empreenderdinheiro';
  }
  submit() {
    if (this.loginForm.valid) {
      this.loginForm.disable();
      this.loading = true;
      this.error = undefined;
      this.submitted = true;
      this.userService
        .create({
          fullName: this.loginForm.value.fullName,
          email: this.loginForm.value.email,
          password: this.loginForm.value.password,
          origin: this.getOrigin(),
          ...(() => {
            if (localStorage.activedaccount == 'true') {
              delete localStorage.activedaccount;
              return { selectusActive: true };
            }
            return {};
          })() as any
        })
        .subscribe(
          () => {            
            alert('Cadastrado com sucesso. Por favor, efetue seu login.');
            this.router.navigate(['/login']);
          },
          (response) => {
            this.loginForm.enable();
            this.loading = false;
            this.error = response.error.error;
          }
        );
    } else {
      this.error = 'Verifique suas informações.';
    }
  }
}
