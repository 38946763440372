import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { debounceTime, filter, switchMap } from 'rxjs/operators';
import { controleGroup } from '../../shared/controls';
import { ApoloService } from '../../shared/apolo.service';

@Component({
  selector: 'app-painel-de-controle',
  templateUrl: './painel-controle.component.html',
  styleUrls: ['./painel-controle.component.scss']
})
export class PainelControleComponent implements OnInit {
  formGroup: FormGroup;
  constructor(
    public apoloService: ApoloService,
  ) {
    this.formGroup = apoloService.formGroup;
    this.autoMaskLimit();
  }
  autoMaskLimit() {
    this.formGroup.get('periodoDeAplicacao').valueChanges
      .pipe(
        debounceTime(200),
        filter((value) => value > 50)
      ).subscribe((_) => {
        this.formGroup.get('periodoDeAplicacao').setValue(50);
      });
    this.formGroup.get('capitalInicialYears').valueChanges
      .pipe(
        debounceTime(200),
        filter((value) => value > 99)
      ).subscribe((_) => {
        this.formGroup.get('capitalInicialYears').setValue(99);
      });
  }
  ngOnInit() { }
  redefinir() {
    this.apoloService.formGroup.setValue(controleGroup().value);
    this.apoloService.reloadInfo$.next(true);
  }
}
