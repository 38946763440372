<mat-nav-list>

  <div *ngIf="activeFolder === true" style="
  padding-top: 24px;
  padding-left: 18px;
  margin-bottom: -20px;
">
    <button (click)="backFolder()" mat-stroked-button color="primary" style="border-radius: 30px; margin-bottom: 24px; color: #109cf1;">
      <svg style="width:24px;height:24px; float: left; margin-top: 5px; margin-right: 6px;" viewBox="0 0 24 24">
      <path fill="currentColor" d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z" />
  </svg>
  Voltar para a TechFinance
  </button>
  </div>

  <span>Menu:</span>
  <a mat-list-item routerLinkActive="list-item-active" routerLink="/painel-controle">
    <mat-icon matListIcon>table</mat-icon>
    Painel de Controle
  </a>
  <a mat-list-item routerLinkActive="list-item-active" routerLink="/dashboard">
    <mat-icon matListIcon>analytics</mat-icon>
    Dashboard
  </a>

  <a *ngIf="origin=='empreenderdinheiro' && activeFolder === false" mat-list-item routerLinkActive="list-item-active"
    [href]="['https:/', 'investidor.' +  baseUrl , accessToken].join('/')">
    <mat-icon matListIcon>assignment_return</mat-icon>
    Retornar ao Investidor 5.0
  </a>
  <a *ngIf="origin=='techfinance' && activeFolder === false" mat-list-item routerLinkActive="list-item-active"
    [href]="['https:/', 'techfinance.' +  baseUrl , accessToken].join('/')">
    <mat-icon matListIcon>assignment_return</mat-icon>
    Retornar à TechFinance
  </a>
  
  <a mat-list-item routerLinkActive="list-item-active" class="d-block d-sm-none" [matMenuTriggerFor]="menu">    
      <mat-icon matListIcon>exit_to_app</mat-icon>
      Sair    
    <mat-menu #menu="matMenu">
      <button (click)="logout()" mat-menu-item>
        <mat-icon>exit_to_app</mat-icon>
        Sair
      </button>
    </mat-menu>
  </a>
  


  <span>Suporte:</span>
  <!--<a mat-list-item routerLinkActive="list-item-active" href="https://empreenderdinheiro.com.br/perfis-masterplan/"
    target="_blank">
    <mat-icon matListIcon>book</mat-icon>
    E-books
  </a>-->
  <a mat-list-item (click)="openModal()">
    <mat-icon matListIcon>play_circle_outline</mat-icon>
    Comece por aqui
  </a>
  <div class="footer">
    Powered by <a href="https://empreenderdinheiro.com.br">Empreender Dinheiro</a>
  </div>

</mat-nav-list>