import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { ApoloService } from '../../../shared/apolo.service';

@Component({
  selector: 'app-input-number',
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.scss']
})
export class InputNumberComponent implements OnInit {

  @Input()
  prefix: string;

  @Input()
  suffix: string;

  @Input()
  align: string = 'right';

  @Input()
  label: string;

  @Input()
  money: boolean = true;


  @Input()
  negative: boolean = true;

  @Input()
  thousand: string = '.';
  @Input()
  decimalCaracter: string = ',';

  @Input()
  control: FormControl;

  inputValue = new FormControl();

  constructor(public apolo: ApoloService, ) { }

  ngOnInit(): void {
    this.loadValue();
    this.subscribeValues();

    this.apolo.reloadInfo$.subscribe(() => {
      this.loadValue();
    });
  }
  subscribeValues() {
    this.inputValue.valueChanges.subscribe((value) => this.control.patchValue(this.parse(value)));
  }
  loadValue() {
    if (this.control.value == undefined || this.control.value == null) return;
    this.inputValue.setValue(
      this.stringify(this.control.value),
    );
  }
  addOne() {
    this.inputValue.setValue(this.stringify(this.parse(this.inputValue.value) + 1));
  }
  removeOne() {
    this.inputValue.setValue(this.stringify(this.parse(this.inputValue.value) - 1));
  }
  parse(value) {
    return Number(value.split('.').join('').replace(',', '.'));
  }
  stringify(value) {
    let number = Number(value.toString());
    var config: any = {
      minimumFractionDigits: 2,
    };
    return number.toLocaleString('pt-BR', config);
  }

  onKeydownEvent(event: KeyboardEvent): void {
    console.log('iriri', event);
    if (event.keyCode === 13 && event.shiftKey) {
      // On 'Shift+Enter' do this.......
    }
  }

}
