<mat-form-field>
    <mat-label *ngIf="label">{{label}}</mat-label>
    <span *ngIf="prefix" matPrefix><b>{{prefix}}&nbsp;&nbsp;</b></span>
    <input [style.textAlign]="align" type="text" matInput class="form-number" name="value"
        [brmasker]="{money: money, thousand: thousand,  decimalCaracter: decimalCaracter, form: inputValue}" autofocus
        [formControl]="inputValue" (keyup)="onKeydownEvent($event)">
    <span *ngIf=" suffix" matSuffix><b>{{suffix}}&nbsp;&nbsp;</b></span>

    <span matSuffix>
        <div class="min-button" (click)="addOne()">
            <mat-icon>add</mat-icon>
        </div>
        <div class="min-button" (click)="removeOne()">
            <mat-icon>remove</mat-icon>
        </div>
    </span>
</mat-form-field>