import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { AuthFolderService } from './auth-folder.service';

@Component({
  selector: 'app-callback-folder',
  templateUrl: './callback-folder.component.html',
  styleUrls: ['./callback-folder.component.scss']
})
export class CallbackFolderComponent implements OnInit {

  constructor(public oidcSecurityService: OidcSecurityService, private router: Router, private route: ActivatedRoute, private authFolderService: AuthFolderService,) { }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.authFolderService.setAccess(params.token);
      this.router.navigate(['/painel-controle']);
    });
  }

}
