<div class="print-chart">
    <div style="width: 100%; height: 440px; min-width: 620px; padding-bottom: 24px; margin-left: -12px;">

        <ngx-charts-bar-vertical [scheme]="colorScheme" [results]="projectionChart|async" [gradient]="gradient"
            [xAxis]="showXAxis" [rotateXAxisTicks]="false" [yAxis]="true" [showYAxisLabel]="false"
            [yAxisTickFormatting]="noEspace" [yAxisTicks]="[0]" [legend]="false" [gradient]="true"
            [trimYAxisTicks]="true" [showDataLabel]="true" [dataLabelFormatting]="boundFormatDataLabel">
            <ng-template #tooltipTemplate let-model="model">
                <B>{{ model.name}} </B>- {{ model.value | currency:'BRL'}}
            </ng-template>
            <ng-template #seriesTooltipTemplate let-model="model">
                <b>{{ model[0].name}} </b>-
                {{ model[0].value | currency:'BRL'}}
            </ng-template>
        </ngx-charts-bar-vertical>
    </div>
</div>