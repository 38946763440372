import { FormGroup, FormControl, Validators } from "@angular/forms";

export function controleGroup() {
    return new FormGroup({
        capitalInicialYears: new FormControl(30, [Validators.nullValidator, Validators.required, Validators.max(99)]),
        capitalInicial: new FormControl(5000, [Validators.nullValidator, Validators.required]),
        aporte: new FormGroup({
            value: new FormControl(1000, [Validators.nullValidator, Validators.required]),
            type: new FormControl('montly', [Validators.nullValidator, Validators.required]),
        }),
        taxaDaAplicacao: new FormGroup({
            value: new FormControl(10, [Validators.nullValidator, Validators.required]),
            type: new FormControl('yearly', [Validators.nullValidator, Validators.required]),
        }),
        periodoDeAplicacao: new FormControl(25, [Validators.nullValidator, Validators.required, Validators.max(50)]),
        inflacao: new FormGroup({
            value: new FormControl(3.75, [Validators.nullValidator, Validators.required]),
            type: new FormControl('yearly', [Validators.nullValidator, Validators.required]),
        }),
        dynamic: new FormControl(false, [Validators.nullValidator, Validators.required]),

        advancedConfiguration: new FormGroup({
            startPlanning: new FormControl(0, [Validators.nullValidator, Validators.required]),
            fixInflation: new FormControl(true, [Validators.nullValidator, Validators.required]),
            fixInflationOnAporte: new FormControl(true, [Validators.nullValidator, Validators.required]),
            aporteCrescimento: new FormGroup({
                value: new FormControl(true, [Validators.nullValidator, Validators.required]),
                percentage: new FormControl(1, [Validators.nullValidator, Validators.required]),
            }),
            impostoDeRenda: new FormGroup({
                value: new FormControl(true, [Validators.nullValidator, Validators.required]),
                percentage: new FormControl(15, [Validators.nullValidator, Validators.required]),
            }),
        })
    });
}