import { Component, OnInit } from '@angular/core';
import { ApoloService } from '../../../shared/apolo.service';

@Component({
  selector: 'app-projection-chart',
  templateUrl: './projection-chart.component.html',
  styleUrls: ['./projection-chart.component.scss']
})
export class ProjectionChartComponent implements OnInit {

  colorScheme = {
    domain: ['#005a74']
  };

  view: any[] = [700, 400];

  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  showXAxisLabel = true;
  xAxisLabel = 'Country';
  showYAxisLabel = true;
  yAxisLabel = 'Population';
  projectionChart: any;
  projectionChartData: any = [];
  boundFormatDataLabel: any;
  count = 0;

  constructor(
    public apolo: ApoloService,
  ) {
    this.projectionChart =
      apolo?.projectionChart();
    this.projectionChart.subscribe((i) => this.projectionChartData = i);
    this.boundFormatDataLabel = this.formatDataLabel.bind(this);
  }

  ngOnInit(): void {
  }
  noEspace(e) {
    return '';
  }
  public formatDataLabel(value) {
    if (value < 1000) {
      return 'R$' + ProjectionChartComponent.stringify(value);
    }
    return ProjectionChartComponent.stringify(value / 1000) + 'k';
  };
  static parse(value) {
    return Number(value.toLocaleString().split('.').join('').replace(',', '.'));
  }
  static stringify(value) {
    let number = Number(value.toString());
    var config: any = {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    };
    return number.toLocaleString('pt-BR', config);
  }

}
