import { Component, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { ApoloService } from '../../shared/apolo.service';

@Component({
  selector: 'app-aportes',
  templateUrl: './aportes.component.html',
  styleUrls: ['./aportes.component.scss']
})
export class AportesComponent implements OnInit {

  formGroup: FormGroup;
  formYear: FormArray;
  constructor(
    public apolo: ApoloService,
  ) {
    this.formGroup = apolo.formGroup;
    this.formYear = apolo.formYear;
  }
  ngOnInit() { }

  applyToAll(item) {
    this.formYear.controls.forEach((control) => {
      control.get('dynamic').setValue(item.get('dynamic').value);
    });
    this.apolo.reloadInfo$.next(true);
  }
}
