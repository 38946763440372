import { Injectable } from '@angular/core';
import { controleGroup } from './controls';
import { MatSnackBar } from '@angular/material/snack-bar';
import { debounceTime, skip, filter, map, startWith, last, retry, switchMap, mergeMap, mergeAll, switchAll, zipAll, tap } from 'rxjs/operators';
import { FormGroup, FormArray, FormControl } from '@angular/forms';
import * as moment from 'moment';
import { zip, merge, from, ReplaySubject, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { AuthFolderService } from '../callback-folder/auth-folder.service';

@Injectable({
  providedIn: 'root'
})
export class ApoloService {
  formGroup = controleGroup();
  reloadInfo$ = new ReplaySubject();
  formYear: FormArray = new FormArray(
    []
  );
  constructor(
    private _snackBar: MatSnackBar,
    private httpService: HttpClient,
    public authFolderService: AuthFolderService,
    private oidcSecurityService: OidcSecurityService,
  ) {
    this.onStart();
  }
  onStart() {
    this.oidcSecurityService.userData$
    .pipe(
      switchMap((auth) => {
        return this.getValues();
      })
    ).subscribe((values: any) => {
      if(JSON.stringify(values) !== '{}') {
        // fix dynamic
        values.dynamic = false;
        console.log('set value', values);
        this.formGroup.setValue(values);
        this.reloadInfo$.next(values);
      }
      this.onServiceInit();
    })
  }
  onServiceInit() {
    this.buildAportes();
    this.loadSubscribe();
    this.formGroup.valueChanges
      .pipe(
        debounceTime(1000),
      ).subscribe(() => {
        if (this.formGroup.valid) return this.saveValue();
        return this.toastError();
      });
  }
  get header() {
    let token = this.oidcSecurityService.getToken();
    if(this.authFolderService.authenticated()) {
      token = this.authFolderService.getAccess();
    }
    return {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + token,
      }),
    };
  }
  getValues() {
    return this.httpService.get(environment.url + 'apolo/config', this.header);
  }
  saveValue() {
    this.httpService.put(environment.url + 'apolo/config', this.formGroup.value, this.header).subscribe((_) => {});
  }

  toastError() {
    this._snackBar.open('Informações inválidas, insira dados válidos para que sejam salvas.');
  }
  loadSubscribe() {
    this.formGroup.valueChanges
      .pipe(
        filter((value) => value.dynamic == false),
        debounceTime(1500),
      )
      .subscribe(() => this.buildAportes())
  }
  get isDynamic() {
    return this.formGroup.get('dynamic').value;
  }
  get fixInflationOnAporte() {
    return this.formGroup.get('advancedConfiguration').get('fixInflationOnAporte').value;
  }
  get fixInflation() {
    return this.formGroup.get('advancedConfiguration').get('fixInflation').value;
  }
  get yearAporte() {
    const value: number = this.formGroup.get('aporte').get('value').value;
    if (this.formGroup.get('aporte').get('type').value === 'montly') return value * 12;
    return Number(value);
  }
  get montlyAporte() {
    const value: number = this.formGroup.get('aporte').get('value').value;
    if (this.formGroup.get('aporte').get('type').value === 'yearly') return value / 12;
    return Number(value);
  }
  get yearlyInflation() {
    const value: number = this.formGroup.get('inflacao').get('value').value;
    if (this.formGroup.get('inflacao').get('type').value === 'montly') return Number(Math.pow(value, 12));
    return Number(value);
  }
  get montlyInflation() {
    const value: number = this.formGroup.get('inflacao').get('value').value;
    if (this.formGroup.get('inflacao').get('type').value === 'yearly') return Number(((Math.pow((1 + (value / 100)), (1 / 12)) - 1) * 100));
    return Number(value);
  }
  get yearlyTaxaDaAplicacao() {
    const value: number = this.formGroup.get('taxaDaAplicacao').get('value').value;
    if (this.formGroup.get('taxaDaAplicacao').get('type').value === 'montly') {
      return Number(value * 12);
    }
    return Number(value);
  }
  get montlyTaxaDaAplicacao() {
    const value: number = this.formGroup.get('taxaDaAplicacao').get('value').value;
    if (this.formGroup.get('taxaDaAplicacao').get('type').value === 'yearly') return Number(((Math.pow((1 + (value / 100)), (1 / 12)) - 1) * 100));
    return Number(value);
  }
  get capitalInicial() {
    return this.formGroup.get('capitalInicial').value;
  }
  get impostoDeRenda() {
    return this.formGroup.get('advancedConfiguration').get('impostoDeRenda').value;
  }
  patrimonioWithFixInflation(item) {
    return this.formGroup.get(`advancedConfiguration`).value.fixInflation ? item?.valuePresente : item.valueTotal;
  }
  database(filter = true) {
    return this.databaseGenerate()
      .pipe(
        map((items) => {
          if (filter == false) {
            return items;
          }
          return items.filter((item) => item.keyYear < this.formGroup.get('periodoDeAplicacao').value);
        }),
        switchMap((lastValue) => {
          return of(lastValue);
        })
        )
      ;
  }
  databaseGenerate() {
    return this.formYear.valueChanges
      .pipe(
        startWith(this.formYear.value),
        debounceTime(500),
        map((yearList) => {
          const montlyList = [];
          let acumulado = this.capitalInicial;
          let taxa = this.montlyTaxaDaAplicacao;
          let jurosAcumulados = 0;
          if (this.impostoDeRenda.value == true) {
            taxa = taxa * (1 - (this.impostoDeRenda.percentage / 100));
          }

          return yearList.reduce((prev, curr, keyYear) => {
            for (let index = 0; index < 12; index++) {

              let configurationValue = this.formGroup.value;
              let keyIndex = (keyYear * 12 + index) + 1;

              let deposity = this.montlyAporte;
              let lastValueTotal = acumulado;
              let lastValuePresente = acumulado;

              // Sobreesecreve o aporte pelo aporte anterior
              if (keyIndex > 1) {
                //caso o deposity seja mockado pelo aportes dinamicos
                deposity = montlyList[montlyList.length - 1].deposity;
                lastValueTotal = montlyList[montlyList.length - 1].valueTotal;
                lastValuePresente = montlyList[montlyList.length - 1].valuePresente;
              }



              if (configurationValue.advancedConfiguration.fixInflationOnAporte == true) {
                // Adiciona correção da inflação
                deposity = deposity * (1 + (this.montlyInflation / 100));

              }
              if (configurationValue.advancedConfiguration.aporteCrescimento.value == true) {
                // Adiciona crescimento do salário] configurationValue.advancedConfiguration.aporteCrescimento.percentage
                let aporteCrescimentoSalario = Number(((((1 + (configurationValue.advancedConfiguration.aporteCrescimento.percentage / 100)) ** (1 / 12)) - 1) * 100));
                deposity = deposity * (1 + (aporteCrescimentoSalario / 100));
              }



              const dynamicAport = this.formYear.at(keyYear).get('dynamic').value;

              if (this.isDynamic == true && dynamicAport != null) {
                deposity = dynamicAport / 12;

                if (configurationValue.advancedConfiguration.fixInflationOnAporte == true) {
                  let fixInflation = Math.pow((1 + ((Number(this.montlyInflation.toPrecision(5))) / 100)), keyIndex);
                  deposity = deposity * fixInflation;

                }
                if (configurationValue.advancedConfiguration.aporteCrescimento.value == true) {
                  let aporteCrescimento = Math.pow((1 + ((Number(configurationValue.advancedConfiguration.aporteCrescimento.percentage.toPrecision(9)) / 12) / 100)), keyIndex);
                  deposity = deposity * aporteCrescimento;
                }
              }


              //
              ///
              let juros = lastValueTotal * (taxa / 100);
              acumulado = acumulado + deposity;


              jurosAcumulados = jurosAcumulados + juros;

              let valueTotal = lastValueTotal + juros + deposity;

              let valuePresente = lastValuePresente;
              valuePresente = (valuePresente + deposity) * (1 + ((1 + (taxa / 100)) / (1 + (this.montlyInflation / 100)) - 1));

              ///
              //
              let date = moment().add(keyIndex, 'months').toDate();


              montlyList.push({
                keyIndex,
                keyYear,
                valueTotal,
                valuePresente,
                deposity: deposity,
                acumulado,
                jurosAcumulados,
                juros,
                date

              });


              //end mont montly

            }
            return montlyList;
          }, []);
        })
      );
  }
  databasePerYear() {
    return this.database()
      .pipe(
        map(
          (date) => Object.values(date.reduce((prev, curr) => {

            prev[curr.keyYear] = curr;
            return prev;
          }, {}))
        )
      );
  }

  projectionFormation() {
    const years = [5,
      10,
      15,
      20,
      25,
      30,
      35,
      40,
      45,
      50,
    ];
    return from(
      years
    )
      .pipe(
        map(param => this.projectionByYear(param)),
        zipAll(),
      )
  }
  lastItem() {
    return this.database()
      .pipe(
        map((data) => {
          return data[data.length - 1];
        })
      );
  }
  rpmLastItem() {
    return this.lastItem()
      .pipe(
        map((lastItem) => {
          return ((this.patrimonioWithFixInflation(lastItem) * ((this.yearlyTaxaDaAplicacao - this.yearlyInflation) / 100)) * (1 - (this.impostoDeRenda.percentage / 100))) / 12;
        })
      );
  }
  lastItemJurosEfetivos() {
    return this.lastItem()
      .pipe(
        map((lastItem) => {
          if (this.fixInflation == true) {
            return lastItem.valuePresente - lastItem.acumulado;
          }
          return lastItem.jurosAcumulados;
        })
      );
  }
  projectionByYear(yearKey) {
    return this.lastItemJurosEfetivosPercentageByYear(yearKey)
      .pipe(
        map((jurosPercent) => {
          jurosPercent = jurosPercent;
          return {
            year: yearKey.toString(),
            aportePercent: 1 - jurosPercent,
            jurosPercent,
          };
        })
      );

  }
  lastItemJurosEfetivosPercentageByYear(keyYear) {
    keyYear = keyYear - 1;
    return this.database(false)
      .pipe(
        map((data) => {
          const item = data.reverse().find((item) => item.keyYear == keyYear);
          if (item == undefined) { return 1; }
          return 1 - (item.acumulado / this.patrimonioWithFixInflation(item));
        })
      );
  }
  lastItemJurosEfetivosPercentage() {
    return this.database()
      .pipe(
        map((data) => {
          const lastItem = data[data.length - 1];
          if (lastItem == undefined) { return 1; }
          return 1 - (lastItem.acumulado / this.patrimonioWithFixInflation(lastItem));
        })
      );
  }
  firstMillion() {
    return this.database(false)
      .pipe(
        map((data) => {
          return data.find((item) => this.patrimonioWithFixInflation(item) >= 1000000);
        })
      );
  }
  projectionChart() {
    return this.databasePerYear()
      .pipe(
        map((data) => {
          return data.map((item: any) => {
            return {
              "value": this.patrimonioWithFixInflation(item),
              "name": item.date.getFullYear().toString(),
            };
          });
        }),
        tap((data) => {
          return data;
        })
      );
  }

  buildAportes() {
    const years = [...Array(50).keys()];
    const aportes: Array<FormGroup> = years.map((yearCount: number) => {
      let valueNumber = this.yearAporte;

      const value = { projection: valueNumber, dynamic: valueNumber };
      return value;
    })
      .map((item: any) => new FormGroup({
        projection: new FormControl(item.projection),
        dynamic: new FormControl(null),
      }));
    this.formYear.clear();
    aportes.forEach((control, key) => this.formYear.setControl(key, control));
  }
}
